import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import { DefaultLayout } from './compnents/defaultLayout';
import { NoPage } from './compnents/noPage';

const AnchorLibrary = React.lazy(() => import('./pages/anchorLibrary/index'));
const HomePage = React.lazy(() => import('./pages/menuPage/index'));
const CategoryManagement = React.lazy(() => import('./pages/categoryManagement'));
const PurchasingInstructions = React.lazy(() => import('./pages/purchasingInstructions'));
const PriceTemplate = React.lazy(() => import('./pages/priceTemplate'));
const Administrators = React.lazy(() => import('./pages/administrators/index'));
const DemandConfig = React.lazy(() => import('./pages/demandConfig/index'));
const AnchorManagement = React.lazy(() => import('./pages/anchorManagement/index'));
const Login = React.lazy(() => import('./pages/login'));
const Community = React.lazy(() => import('./pages/community'));
const Dashboard = React.lazy(() => import('./pages/dashboard'));

const UserManagement = React.lazy(() => import('./pages/userManagement'));
const OrderManagement = React.lazy(() => import('./pages/orderManagement'));
const MemberManagement = React.lazy(() => import('./pages/memberManagement'));
export type MenuDataItem = {
  name?: string;
  children?: MenuDataItem[];
} & RouteObject;

export const routes: MenuDataItem[] = [
  {
    name: '',
    element: <DefaultLayout />,
    children: [
      {
        path: '/pbb-pc-anchorcircle/home/index',
        title: '首页',
        element: <HomePage />,
      },
      {
        path: '/pbb-pc-anchorcircle/login/index',
        title: '登录',
        element: <Login />,
      },
      {
        path: '/pbb-pc-anchorcircle/purchasingInstructions/index',
        title: '购买须知',
        element: <PurchasingInstructions />,
      },
      {
        path: '/pbb-pc-anchorcircle/community/index',
        title: '社群管理',
        element: <Community />,
      },
      {
        path: '/pbb-pc-anchorcircle/priceTemplate/index',
        title: '价格模版',
        element: <PriceTemplate />,
      },
      {
        path: '/pbb-pc-anchorcircle/categoryManagement/index',
        title: '类别管理',
        element: <CategoryManagement />,
      },
      {
        path: '/pbb-pc-anchorcircle/administrators/index',
        title: '管理员',
        element: <Administrators />,
      },
      {
        path: '/pbb-pc-anchorcircle/demandConfig/index',
        title: '需求配置',
        element: <DemandConfig />,
      },
      {
        path: '/pbb-pc-anchorcircle/anchorManagement/index',
        title: '主播管理',
        element: <AnchorManagement />,
      },
      {
        name: '仪表盘',
        path: '/dashboard',
        element: <Dashboard />,
      },
      {
        path: '/pbb-pc-anchorcircle/anchorLibrary',
        name: '表格测试',
        // component: React.lazy(() => import('./pages/anchorLibrary/index')),
        element: <AnchorLibrary />,
      },
      {
        path: '/pbb-pc-anchorcircle/userManagement',
        name: '用户管理',
        element: <UserManagement />,
      },
      {
        path: '/pbb-pc-anchorcircle/orderManagement',
        name: '订单管理',
        element: <OrderManagement />,
      },
      {
        path: '/pbb-pc-anchorcircle/memberManagement',
        name: '会员管理',
        element: <MemberManagement />,
      },
      {
        path: '/',
        element: (
          <Navigate
            replace
            to="/pbb-pc-anchorcircle/home/index"
          />
        ),
      },
      {
        path: '*',
        element: <NoPage />,
      },
    ],
  },
];

export function RenderRoutes() {
  return useRoutes(routes);
}
